import React from 'react';
import { Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const VideoServices = () => {
    const classes = useStyles();

    return (
        <Paper className={classes.pageWrapper}>
            <Typography variant="h3" className={classes.header}>Videotjenester</Typography>

            <Typography variant="h5" className={classes.paragraphHeader}>Flerkameraproduksjon</Typography>
            <Typography variant="body1">
                VAVIT har spesialisert seg på flerkameraproduksjon av events, som kulturelle arrangement (konserter, teater o.l.), konferanser, og andre typer arrangement.
                <br />
                <br />
                Vi har utstyr for å mikse opptil åtte kamera samtidig i oppløsning opptil 1080p50, og sende til storskjerm og/eller streaming, samt opptak av bildemiksen og råopptak fra hvert kamera for etterarbeid.
            </Typography>

            <Typography variant="h5" className={classes.paragraphHeader}>Livestreaming</Typography>
            <Typography variant="body1">
                Du kan få ditt arrangement sendt direkte til din foretrukne strømmetjeneste, som f.eks. YouTube, Facebook, Vimeo og Twitch.
                <br />
                Det er også mulig med simultanstreaming til flere destinasjoner samtidig.
            </Typography>

            <Typography variant="h5" className={classes.paragraphHeader}>Redigering</Typography>
            <Typography variant="body1">
                Vi redigerer ditt videomateriale etter dine ønsker og behov. Dette kan være alt fra enkel klipping, til omfattende flerkameraredigering og grading. Lydredigering utføres i samarbeid med <a href="https://gilyd.com" target="_blank" rel="noopener noreferrer">GI Lyd</a>.
            </Typography>

            <Typography variant="h5" className={classes.paragraphHeader}>Distribusjon</Typography>
            <Typography variant="body1">
                Om du har videomateriell du ønsker å distribuere enten på fysisk format (Blu-ray / DVD) eller gjennom en digital betalingsløsning, så er dette noe vi kan bistå med.
            </Typography>

            <Typography variant="h5" className={classes.paragraphHeader}>Digitalisering</Typography>
            <Typography variant="body1">
                Har du eldre videokassetter av type VHS, VHS-C, Hi-8, VIDEO-8, miniDV eller tilsvarende, kan du få disse digitalisert og returnert enten på minnepinne, DVD, eller digitalt via en nettskytjeneste.
            </Typography>
        </Paper>
    )
};

const useStyles = makeStyles((theme) => ({
    pageWrapper: {
        maxWidth: '50rem',
        margin: '0 auto',
        padding: '2rem',
        /* [theme.breakpoints.down('lg')]: {
            padding: '2rem',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '4rem',
        }, */
    },
    header: {
        textAlign: 'center',
        marginBottom: '3rem',
    },
    paragraphHeader: {
        //textAlign: 'center',
        marginTop: '3rem',
        marginBottom: '.7rem',
    },
}));

export default VideoServices;
