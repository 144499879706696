import React from 'react';
import { CardMedia, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EmailIcon from '@material-ui/icons/Email'
import PhoneIcon from '@material-ui/icons/Phone'
import PlaceIcon from '@material-ui/icons/Place'
import InfoIcon from '@material-ui/icons/Info'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ltImg from '../img/lt.jpg';
import giLydImg from '../img/partners/gilyd.png';
import raImg from '../img/partners/ra.png';
import vingVideoImg from '../img/partners/vingVideo.jpg';

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

const Contact = () => {
    const classes = useStyles();

    return (
        <>
            <Typography variant="h3" className={classes.header}>Kontakt</Typography>

            <Card className={classes.card}>
                <CardMedia className={classes.imgWrapper}>
                    <img
                        src={ltImg}
                        className={classes.img}
                        alt="Lars Tore Vassli"
                    />
                </CardMedia>
                <CardContent className={classes.cardContent}>
                    <div className={classes.titleAndName}>
                        <Typography color="textSecondary" gutterBottom>
                            Innehaver
                        </Typography>
                        <Typography variant="h5" component="h2">
                            Lars Tore Vassli
                        </Typography>
                    </div>
                    <div className={classes.linkList}>
                        <List>
                            <ListItemLink href="tel:45502534">
                                <ListItemIcon>
                                    <PhoneIcon />
                                </ListItemIcon>
                                <ListItemText primary="455 02 534" />
                            </ListItemLink>
                            <ListItemLink href="mailto:post@vavit.no">
                                <ListItemIcon>
                                    <EmailIcon />
                                </ListItemIcon>
                                <ListItemText primary="post@vavit.no" />
                            </ListItemLink>
                        </List>
                    </div>
                </CardContent>
            </Card>

            <Card className={classes.card}>
                <Typography variant="h5" className={classes.paragraphHeader}>Firmainformasjon</Typography>
                <List>
                    <ListItemLink
                        href="https://goo.gl/maps/gEsLpzHYwfu"
                        target="_blank"
                    >
                        <ListItemIcon>
                            <PlaceIcon />
                        </ListItemIcon>
                        <ListItemText>
                            <Grid container spacing={1}>
                                <Grid item xs={5} className={classes.headerGrid}>
                                    <strong>Adresse</strong>
                                </Grid>
                                <Grid item xs={7}>
                                    Tiller-ringen 13, 7092 Tiller
                                </Grid>
                            </Grid>
                        </ListItemText>
                    </ListItemLink>
                    <ListItemLink
                        href="https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=913793471"
                        target="_blank"
                    >
                        <ListItemIcon>
                            <InfoIcon />
                        </ListItemIcon>
                        <ListItemText>
                            <Grid container spacing={1}>
                                <Grid item xs={5}>
                                    <strong>Org.nr.</strong>
                                </Grid>
                                <Grid item xs={7}>
                                    913 793 471
                                </Grid>
                            </Grid>
                        </ListItemText>
                    </ListItemLink>
                    <ListItem>
                        <ListItemIcon>
                            <AccountBalanceIcon />
                        </ListItemIcon>
                        <ListItemText>
                            <Grid container spacing={1}>
                                <Grid item xs={5}>
                                    <strong>Kontonr.</strong>
                                </Grid>
                                <Grid item xs={7}>
                                    1503.49.83019
                                </Grid>
                            </Grid>
                        </ListItemText>
                    </ListItem>
                </List>
            </Card>

            <Card className={classes.card}>
                <Typography variant="h5" className={classes.paragraphHeader}>Samarbeidspartnere</Typography>
                <Accordion className={classes.accordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="gilyd-content"
                        id="gilyd-header"
                    >
                        <Typography className={classes.partnerHeader}>GI Lyd v/ Geir Ivar Steigedal</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.accordionContent}>
                            <img
                                src={giLydImg}
                                className={classes.partnerImg}
                                alt="GI Lyd logo"
                            />
                            <Typography><strong>Lydteknikk</strong></Typography>
                            <br />
                            <Typography><strong>Tlf.:&nbsp;</strong>
                                <a href="tel:92214108">922&nbsp;14&nbsp;108</a>
                            </Typography>
                            <Typography><strong>E-post:&nbsp;</strong>
                                <a href="mailto:post@gilyd.com">post@gilyd.com</a>
                            </Typography>
                            <Typography><strong>Web:&nbsp;</strong>
                                <a href="https://gilyd.com" target="_blank" rel="noopener noreferrer">gilyd.com</a>
                            </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className={classes.accordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="ra-content"
                        id="ra-header"
                    >
                        <Typography className={classes.partnerHeader}>RA Audio v/ Arild Raanes</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.accordionContent}>
                            <img
                                src={raImg}
                                className={classes.partnerImg}
                                alt="RA Audio logo"
                            />
                            <Typography><strong>Totalleverandør av lyd/lys/scene</strong></Typography>
                            <br />
                            <Typography><strong>Tlf.:&nbsp;</strong>
                                <a href="tel:91882551">918&nbsp;82&nbsp;551</a>
                            </Typography>
                            <Typography><strong>E-post:&nbsp;</strong>
                                <a href="mailto:post@raaudio.no">post@raaudio.no</a>
                            </Typography>
                            <Typography><strong>Web:&nbsp;</strong>
                                <a href="http://www.raaudio.no" target="_blank" rel="noopener noreferrer">raaudio.no</a>
                            </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className={classes.accordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="vingVideo-content"
                        id="vingVideo-header"
                    >
                        <Typography className={classes.partnerHeader}>Ving Video v/ Gunnar Gravdal</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.accordionContent}>
                            <img
                                src={vingVideoImg}
                                className={classes.partnerImg}
                                alt="Ving Video logo"
                            />
                            <Typography><strong>Ving Video holder til i Lillehammer og tilbyr tjenester innen videofilming og redigering. Utfører flerkameraproduksjon og streaming av eventer.</strong></Typography>
                            <br />
                            <Typography><strong>Tlf.:&nbsp;</strong>
                                <a href="tel:45671440">456&nbsp;71&nbsp;440</a>
                            </Typography>
                            <Typography><strong>E-post:&nbsp;</strong>
                                <a href="mailto:vingvideo@bbnett.no">vingvideo@bbnett.no</a>
                            </Typography>
                            <Typography><strong>Web:&nbsp;</strong>
                                <a href="https://www.facebook.com/Ving-Video-2142960946008405/" target="_blank" rel="noopener noreferrer">https://www.facebook.com/Ving-Video-2142960946008405/</a>
                            </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className={classes.accordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="einar-content"
                        id="einar-header"
                    >
                        <Typography className={classes.partnerHeader}>Einar Myren</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.accordionContent}>
                            <Typography><strong>Kameraoperatør</strong></Typography>
                            <br />
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className={classes.accordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="geirInge-content"
                        id="geirInge-header"
                    >
                        <Typography className={classes.partnerHeader}>Geir Inge Fiske</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.accordionContent}>
                            <Typography><strong>Kameraoperatør</strong></Typography>
                            <br />
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className={classes.accordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="helene-content"
                        id="helene-header"
                    >
                        <Typography className={classes.partnerHeader}>Helene Braa</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.accordionContent}>
                            <Typography><strong>Kameraoperatør</strong></Typography>
                            <br />
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className={classes.accordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="torgeir-content"
                        id="torgeir-header"
                    >
                        <Typography className={classes.partnerHeader}>Torgeir Steinveg</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.accordionContent}>
                            <Typography><strong>Kameraoperatør</strong></Typography>
                            <br />
                        </div>
                    </AccordionDetails>
                </Accordion>
            </Card>
        </>
    )
};

const useStyles = makeStyles(() => ({
    header: {
        textAlign: 'center',
        marginBottom: '2rem',
    },
    card: {
        textAlign: 'center',
        margin: '2rem auto 4rem auto',
        maxWidth: '30rem',
    },
    imgWrapper: {
        paddingTop: '1.5rem',
        display: 'block',
        margin: 'auto',
    },
    img: {
        height: '10rem',
        borderRadius: '50%',
    },
    cardContent: {
        paddingTop: 0,
        paddingBottom: '0 !important',
    },
    titleAndName: {
        margin: '1rem',
    },
    linkList: {
        maxWidth: '12rem',
        margin: '0 auto',
    },
    headerGrid: {
        margin: 'auto 0',
    },
    accordion: {
        textAlign: 'left',
    },
    accordionContent: {
        margin: '0 2rem',
    },
    paragraphHeader: {
        textAlign: 'center',
        marginTop: '1rem',
        marginBottom: '1rem',
    },
    partnerHeader: {
        fontSize: '1.1rem',
    },
    partnerImg: {
        maxWidth: '15rem',
        maxHeight: '5rem',
        marginBottom: '1rem',
    }
}));

export default Contact;
