import React from 'react';
import MuiList from '@material-ui/core/List';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Skeleton from 'react-loading-skeleton';
import ListItem from './list-item';

const List = ({ children, loading, ...props }) => (
  <MuiList {...props}>
    <Divider />
    {loading ? (
      [0, 1, 2, 3, 4].map(val => (
        <ListItem
          key={`skel-${val}`}
          primary={<Skeleton />}
        />
      ))
    ) : children}
  </MuiList>
);

List.propTypes = {
  /** React ListItem components */
  children: PropTypes.any.isRequired,
  loading: PropTypes.bool,
};

List.defaultProps = {
  loading: false,
};

export default List;
