import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItemCollapse from '../List/list-item-collapse';

const useStyles = makeStyles({
  body: {
    fontSize: '1rem',
    height: 0,
    padding: 0,
    border: 0,
  },
  row: {
    width: '100%',
    fontSize: '1rem',
    height: 0,
    padding: 0,
    borderLeft: '0.3rem solid white',
  },
});

const TableRowExpandable = ({
  numberOfCells, open, documents, children,
}) => {
  const classes = useStyles();
  return (
    <TableRow className={classes.row}>
      <TableCell colSpan={numberOfCells} className={classes.body} padding="none">
        <ListItemCollapse documents={documents} isOpen={open}>
          {children}
        </ListItemCollapse>
      </TableCell>
    </TableRow>
  );
};

TableRowExpandable.propTypes = {
  children: PropTypes.any,
  /** Number of columns */
  numberOfCells: PropTypes.number.isRequired,
  /** Show/hide component */
  open: PropTypes.bool.isRequired,
  /** Documents to display in the collapse */
  documents: PropTypes.arrayOf({
    title: PropTypes.string,
    link: PropTypes.string,
    showFileIcon: PropTypes.bool,
  }).isRequired,
};

TableRowExpandable.defaultProps = {
  children: undefined,
};

export default TableRowExpandable;
