import React from 'react';
import { Button } from '@material-ui/core';
import { Grid, makeStyles } from '@material-ui/core';
import { MobileView } from '../components/Views';
import img01 from '../img/gallery/001.jpg';
import img02 from '../img/gallery/002.jpg';
import img03 from '../img/gallery/003.jpg';
import img04 from '../img/gallery/004.jpg';
import img05 from '../img/gallery/005.jpg';
import img06 from '../img/gallery/006.jpg';
import img07 from '../img/gallery/007.jpg';
import img08 from '../img/gallery/008.jpg';
import img09 from '../img/gallery/009.jpg';
import img10 from '../img/gallery/010.jpg';
import img11 from '../img/gallery/011.jpg';
import img12 from '../img/gallery/012.jpg';
import img13 from '../img/gallery/013.jpg';
import img14 from '../img/gallery/014.jpg';
import img15 from '../img/gallery/015.jpg';
import { pages } from '../App';
import ImageGallery from 'react-image-gallery';

import "../../node_modules/react-image-gallery/styles/css/image-gallery.css";

function shuffleArray(array) {
    let i = array.length - 1;
    for (; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
}


const images = [
    { original: img01, originalAlt: 'img01' },
    { original: img02, originalAlt: 'img02' },
    { original: img03, originalAlt: 'img03' },
    { original: img04, originalAlt: 'img04' },
    { original: img05, originalAlt: 'img05' },
    { original: img06, originalAlt: 'img06' },
    { original: img07, originalAlt: 'img07' },
    { original: img08, originalAlt: 'img08' },
    { original: img09, originalAlt: 'img09' },
    { original: img10, originalAlt: 'img10' },
    { original: img11, originalAlt: 'img11' },
    { original: img12, originalAlt: 'img12' },
    { original: img13, originalAlt: 'img13' },
    { original: img14, originalAlt: 'img14' },
    { original: img15, originalAlt: 'img15' },
];

const Main = () => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.imageGalleryWrapper}>
                <ImageGallery
                    items={shuffleArray(images)}
                    className={classes.imageGallery}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showThumbnails={false}
                    showBullets={true}
                    autoPlay={true}
                    lazyLoad={true}
                    slideInterval={5000}
                    slideDuration={500}
                />
            </div>

            <MobileView>
                <Grid container className={classes.grid}>
                    <Grid item xs={12} className={classes.mobileNavBtnGrid}>
                        {Object.values(pages).map((page) => (
                            <div key={page.path}>
                                <Button variant="contained"
                                    color="primary"
                                    size="large"
                                    className={classes.mobileNavBtn}
                                    onClick={() => { window.location = `#/${page.path}`; }}>
                                    {page.title}
                                </Button>
                                <br />
                            </div>
                        ))}
                    </Grid>
                </Grid>
            </MobileView>
        </>
    )
};

const useStyles = makeStyles(() => ({
    header: {
        textAlign: 'center',
    },
    grid: {
        justifyContent: 'center',
    },
    imageGalleryWrapper: {
        marginBottom: '5rem',
    },
    imgGrid: {
        display: 'flex',
        alignItems: 'center',
    },
    mobileNavBtnGrid: {
        textAlign: 'center',
        paddingBottom: '0 !important',
    },
    mobileNavBtn: {
        margin: '1rem auto',
        width: '50%',
    },
}));

export default Main;
