import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableRow as MuiTableRow } from '@material-ui/core/';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  clickable: {
    cursor: 'pointer',
  },
  row: {
    borderLeft: '0.3rem solid white',
    '&:link, &:visited': {
      textDecoration: 'inherit',
    },
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.common.shaded,
    },
  },
  hovering: {
    '&:hover, &:focus': {
      borderLeft: '0.3rem solid',
      borderColor: theme.palette.primary.main,
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.common.shaded,
      },
      '&:nth-of-type(odd)': {
        backgroundColor: 'inherit',
      },
    },
  },
  manualFocus: {
    borderLeft: '0.3rem solid',
    borderColor: theme.palette.primary.main,
  },
  standard: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.common.shaded,
    },
    borderLeft: '0.3rem solid inherit',
  },
  button: {
    borderLeft: '0.3rem solid white',
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.common.shaded,
    },
    cursor: 'pointer',
    '&:hover': {
      borderColor: theme.palette.primary.light,
    },
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
  },
  expandable: {
    cursor: 'pointer',
    borderLeft: '0.3rem solid white',
    '&:focus, &:hover': {
      backgroundColor: 'inherit',
    },
    '&:nth-of-type(4n + 3)': {
      backgroundColor: theme.palette.common.shaded,
    },
  },
}));

const TableRow = ({
  onClick, children, variant, className, ...other
}) => {
  const classes = useStyles();
  const [focused, setFocused] = useState(false);

  const enterSpaceKeyDown = action => (e) => {
    if (e.which === 32 || e.which === 13) {
      action(e);
      e.preventDefault();
    }
  };
  const c = clsx(
    variant === 'standard' && classes.standard,
    variant === 'button' && classes.button,
    variant === 'expandable' && classes.expandable,
    className,
  );

  if (onClick) {
    other.onKeyDown = enterSpaceKeyDown(e => onClick(e));
    other.onClick = onClick;
  }

  if (onClick) {
    other.tabIndex = 0;
  }

  return (
    <MuiTableRow
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      className={c}
      hover={variant === 'expandable' && (!!onClick || focused)}
      {...other}
    >
      {children}
    </MuiTableRow>
  );
};

TableRow.propTypes = {
  /** Custom onClick handler */
  onClick: PropTypes.func,
  /** TableCell components */
  children: PropTypes.array.isRequired,
  variant: PropTypes.oneOf(['standard', 'button', 'expandable']),
  className: PropTypes.string,
};

TableRow.defaultProps = {
  onClick: undefined,
  variant: 'standard',
  className: '',
};

export default TableRow;
