import React from 'react';
import MuiListItem from '@material-ui/core/ListItem';
import MuiListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  button: {
    borderLeft: '0.3rem solid',
    borderLeftColor: theme.palette.common.shaded,
    '&:hover': {
      borderLeft: '0.3rem solid',
      borderLeftColor: theme.palette.primary.light,
      backgroundColor: 'inherit',
    },
    '&:focus': {
      borderLeft: '0.3rem solid',
      borderLeftColor: theme.palette.primary.main,
      backgroundColor: 'inherit',
    },
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.common.shaded,
    },
  },
  row: {
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.common.shaded,
    },
  },
  expandable: {
    '&:nth-of-type(4n+3)': {
      backgroundColor: theme.palette.common.shaded,
    },
  },
}));


const ListItem = ({
  primary, secondary, children, onClick, Icon, variant, isSelected, ...props
}) => {
  const classes = useStyles();

  return (
    <MuiListItem
      className={clsx(
        variant === 'standard' && classes.row,
        variant === 'button' && classes.button,
        variant === 'expandable' && classes.expandable,
      )}
      button={variant === 'button' || variant === 'expandable'}
      onClick={onClick}
      divider
      {...props}
    >
      {!!Icon && (
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
      )}
      <MuiListItemText primary={primary} secondary={secondary} />
      {children}
      {variant === 'expandable' && (isSelected ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />)}
    </MuiListItem>
  );
};

ListItem.propTypes = {
  /** Title */
  primary: PropTypes.any,
  /** Subtitle */
  secondary: PropTypes.any,
  /** Body (Put ListItemCollapse here) */
  children: PropTypes.any,
  /** On click action (ListItemCollapse opener) */
  onClick: PropTypes.func,
  /** Ikon som blir plassert til venstre for teksten */
  Icon: PropTypes.func,
  variant: PropTypes.oneOf(['standard', 'button', 'expandable']),
  isSelected: PropTypes.bool,
};

ListItem.defaultProps = {
  primary: null,
  secondary: null,
  children: null,
  onClick: () => {},
  Icon: undefined,
  variant: 'standard',
  isSelected: false,
};

export default ListItem;
