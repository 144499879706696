import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const useStyles = makeStyles({
  body: {
    fontSize: '1rem',
    //padding: '0.7rem 0.5rem',
  },
  shrink: {
    width: '0.1%',
  },
  nobreak: {
    whiteSpace: 'nowrap',
  },
});

const CustomTableCell = ({
  children, shrink, nobreak, align, ...props
}) => {
  const classes = useStyles();
  return (
    <TableCell
      align={align}
      className={clsx(classes.body, shrink && classes.shrink, nobreak && classes.nobreak)}
      {...props}
    >
      {children}
    </TableCell>
  );
};

CustomTableCell.propTypes = {
  /** Component inside tablecell */
  children: PropTypes.any,
  /** Align cellcontent */
  align: PropTypes.oneOf(['inherit', 'left', 'center', 'right']),
  /** Set small width to tablecell */
  shrink: PropTypes.bool,
  /** Set if conten should not linebreak */
  nobreak: PropTypes.bool,
  /** Props for Material UI Tablecell component */
  props: PropTypes.any,
};

CustomTableCell.defaultProps = {
  children: '',
  align: 'inherit',
  shrink: false,
  nobreak: false,
  props: null,
};

export default CustomTableCell;
