import React from 'react';
import { Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const References = () => {
    const classes = useStyles();

    return (
        <Paper className={classes.pageWrapper}>
            <Typography variant="h3" className={classes.header}>Referanser</Typography>
            
            <Typography variant="body1">
                Noen tidligere kunder av VAVIT inkluderer:
                <ul className={classes.refList}>
                    <li>Heim kommune</li>
                    <li>Hemne songlag</li>
                    <li>Fagforbundet Heim</li>
                    <li>Skaun kommune</li>
                    <li>Hemne Teaterlag</li>
                    <li>Strinda Ungdomskorps</li>
                    <li>Spelet i Wesselgården</li>
                    <li>Heim rødruss</li>
                    <li>Klungervik Humor &amp; Kanari Acme</li>
                    <li>Big Hand Entertainment</li>
                    <li>Nashville (band)</li>
                    <li>Humorfestivalen i Heim</li>
                    <li>Thamskonferansen</li>
                    <li>Bergmannskonferansen</li>
                    <li>Orkel</li>
                    <li>SALT!</li>
                    <li>Linn-Therese Gjengstø og Barbro Amrud Nilsen</li>
                    <li>Sodin skole</li>
                    <li>Aurerevyen</li>
                    <li>Kirksæterørens Hornmusikk</li>
                    <li>Mannskoret Lerka</li>
                </ul>
            </Typography>
        </Paper>
    )
};

const useStyles = makeStyles(() => ({
    pageWrapper: {
        maxWidth: '50rem',
        margin: '0 auto',
        padding: '2rem',
    },
    header: {
        textAlign: 'center',
        marginBottom: '3rem',
    },
    refList: {
        lineHeight: '2rem'
    }
}));
  
export default References;
