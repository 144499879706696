import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';

const MobileView = ({ children }) => (
  <Hidden lgUp>
    {children}
  </Hidden>
);

MobileView.propTypes = {
  /** Component to render inside Mobile view */
  children: PropTypes.any,
};

MobileView.defaultProps = {
  children: null,
};

export default MobileView;
