import React from 'react';
import { Typography, Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const PreviousWork = () => {
    const classes = useStyles();

    return (
        <Paper className={classes.pageWrapper}>
            <Typography variant="h3" className={classes.header}>Tidligere produksjoner</Typography>

            <Typography variant="body1" className={classes.firstParagraph}>
                De fleste av videoproduksjonene som er utført av VAVIT er ikke offentlig tilgjengelig, etter ønske fra kunde.
                <br />
                Men et knippe av de som er offentlige, er samlet på denne siden.
            </Typography>
            <Grid container className={classes.grid} alignItems="flex-end">
                <Grid item xs={12} sm={6} lg={4} className={classes.gridElement}>
                    <div className={classes.videoHeader}>Livestream-konsert med Linn-Therese Barbro og Svein</div>
                    <iframe title="Livestream-konsert med Linn-Therese, Barbro og Svein" src="https://www.youtube.com/embed/huT2toKgCSA" className={classes.iFrame} width="100%" height="220" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} className={classes.gridElement}>
                    <div className={classes.videoHeader}>Nashville - Country i karantene</div>
                    <iframe title="Nashville livesteam" src="https://www.youtube.com/embed/6oYDIrJbosI" className={classes.iFrame} width="100%" height="220" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} className={classes.gridElement}>
                    <div className={classes.videoHeader}>Hemnerevyen 30 år</div>
                    <iframe title="Hemnerevyen 30 år" src="https://www.youtube.com/embed/BqsBIQJqJY8?si=XmFZZ4FP5wo5agSO&amp;start=437" className={classes.iFrame} width="100%" height="220" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} className={classes.gridElement}>
                    <div className={classes.videoHeader}>The Jan Holberg Project ft. Margrethe Witsø - Something</div>
                    <iframe title="The Jan Holberg Project ft. Margrethe Witsø - Something" src="https://www.youtube.com/embed/rGVAQ-gK2gY" className={classes.iFrame} width="100%" height="220" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} className={classes.gridElement}>
                    <div className={classes.videoHeader}>Førkjånn - Lys i desember - konsert i KulØr'n 26.11.2022</div>
                    <iframe title="Førkjånn - Lys i desember - konsert i KulØr'n 26.11.2022" src="https://www.youtube.com/embed/1xAn8LnW-qQ" className={classes.iFrame} width="100%" height="220" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} className={classes.gridElement}>
                    <div className={classes.videoHeader}>Cavatina - Hans Petter Vik Sæther & Alexey Kurbanov</div>
                    <iframe title="Cavatina - Hans Petter Vik Sæther & Alexey Kurbanov" src="https://www.youtube.com/embed/SfvhPwIKDQw" className={classes.iFrame} width="100%" height="220" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} className={classes.gridElement}>
                    <div className={classes.videoHeader}>Kristin-konsert i Øysteinsalen</div>
                    <iframe title="Kristin-konsert i Øysteinsalen" src="https://www.youtube.com/embed/vo-cM1GpZLc" className={classes.iFrame} width="100%" height="220" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} className={classes.gridElement}>
                    <div className={classes.videoHeader}>Bokshow med Struppuldalingene</div>
                    <iframe title="Bokshow med Struppuldalingene" src="https://www.youtube.com/embed/wPTwqRTk_ek" className={classes.iFrame} width="100%" height="220" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} className={classes.gridElement}>
                    <div className={classes.videoHeader}>Aurerevyen 2019</div>
                    <iframe title="Aurerevyen 2019" src="https://www.youtube.com/embed/ixD2bpFUrWo" className={classes.iFrame} width="100%" height="220" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} className={classes.gridElement}>
                    <div className={classes.videoHeader}>Høydepunkt fra åpning av Sodvinhallen</div>
                    <iframe title="Høydepunkt fra åpning av Sodvinhallen + utdeling av kulturpris til Hemne" src="https://www.youtube.com/embed/MRMyYVGTM14" className={classes.iFrame} width="100%" height="220" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} className={classes.gridElement}>
                    <div className={classes.videoHeader}>Eldres dag i Skaun 2020</div>
                    <iframe title="Eldres dag i Skaun 2020" src="https://www.youtube.com/embed/4LVK_vlvFkk" className={classes.iFrame} width="100%" height="220" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} className={classes.gridElement}>
                    <div className={classes.videoHeader}>Baking på Bua med Harald Lund</div>
                    <iframe title="Baking på Bua med Harald Lund" src="https://www.youtube.com/embed/nW8nz9yVwH4" className={classes.iFrame} width="100%" height="220" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </Grid>

            </Grid>
        </Paper>
    )
};

const useStyles = makeStyles(() => ({
    pageWrapper: {
        maxWidth: '50rem',
        margin: '0 auto',
        padding: '2rem',
    },
    header: {
        textAlign: 'center',
        marginBottom: '3rem',
    },
    firstParagraph: {
        marginBottom: '4rem'
    },
    gridElement: {
        padding: '.5rem',
        marginBottom: '1rem'
    },
    videoHeader: {
        fontSize: '.9rem',
        fontWeight: 'bold'
    },
    iFrame: {
        marginTop: '0.5rem',
    }
}));

export default PreviousWork;
