import React from 'react';
import { MuiThemeProvider, makeStyles } from '@material-ui/core/styles';
import {
  HashRouter, Route, Switch, Link,
} from 'react-router-dom';
import { createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import About from './pages/about';
import NotFound from './pages/not-found';
import Main from './pages/main';
import Navbar from './components/navbar';
import 'react-image-lightbox/style.css';
import Contact from './pages/contact';
import References from './pages/references';
import FacebookLogo from './img/facebook.png';
import YoutubeLogo from './img/youtube.png';
import { DesktopView } from './components/Views';
import PreviousWork from './pages/previous-work';
import VideoServices from './pages/video-services';
import Equipment from './pages/equipment';
import { isIE } from "react-device-detect";

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#2b6fb2',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    background: {
      default: '#e8e8e8'
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    footer: {
      default: '#363636',
    }
  },
});

const useStyles = makeStyles(theme => ({
  pageContainer: {
    position: 'relative',
    minHeight: '100vh',
  },
  navbarAndContent: {
    paddingBottom: '2.5rem',  /* Footer height */
  },
  content: {
    //minHeight: '100vh',
    flex: '1 0 0',
    flexDirection: 'row',
    padding: '0 1.6rem',
    // minWidth: '20rem',
    marginLeft: 'calc(100vw - 100%)',
    marginTop: '3rem',
  },
  wrapper: {
    maxWidth: '68.5rem',
    margin: 'auto',
    marginTop: '1.5rem',
    marginBottom: '1.5rem',
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '2.5rem',  /* Footer height */
    padding: '.4rem .4rem',
    textAlign: 'center',
    backgroundColor: '#363636',
    color: '#fff',
    fontSize: '.7rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '1.1rem',
  },
  footerContents: {
    display: 'contents'
  },
  footerLink: {
    "&:link": {
      color: '#adb7ff',
    },
    "&:hover": {
      color: '#c2c9ff',
    },
    "&:active": {
      color: '#c2c9ff',
    }
  },
  footerImgLink: {
    // height: '1.1rem',
    // lineHeight: '1.1rem',
  },
  footerImgWrapper: {
    display: 'flex'
  },
  footerImg: {
    // position: 'absolute',
    height: '1.1rem',
    WebkitFilter: 'grayscale(60%)',  /* Safari 6.0 - 9.0 */
    filter: 'grayscale(60%)',
    "&:hover": {
      WebkitFilter: 'grayscale(0%)',  /* Safari 6.0 - 9.0 */
      filter: 'grayscale(0%)',
    },
  },
}));

const routes = ({
  '/': {
    component: Main,
    title: 'Forside',
  },
  '/om': {
    component: About,
    title: 'Om VAVIT',
  },
  '/videotjenester': {
    component: VideoServices,
    title: 'Videotjenester',
  },
  '/utstyr': {
    component: Equipment,
    title: 'Utstyr',
  },
  '/referanser': {
    component: References,
    title: 'Referanser',
  },
  '/tidligere-produksjoner': {
    component: PreviousWork,
    title: 'Tidligere produksjoner',
  },
  '/kontakt': {
    component: Contact,
    title: 'Kontakt',
  },
});

export const pages = [
  {
    title: 'Om VAVIT',
    path: 'om'
  },
  {
    title: 'Videotjenester',
    path: 'videotjenester'
  },
  {
    title: 'Utstyr',
    path: 'utstyr'
  },
  {
    title: 'Referanser',
    path: 'referanser'
  },
  {
    title: 'Tidligere produksjoner',
    path: 'tidligere-produksjoner'
  },
  {
    title: 'Kontakt',
    path: 'kontakt'
  },
];

const App = () => {
  const classes = useStyles();

  /* if (isIE) {
    return <div>Denne nettsiden er ikke støttet i Internet Explorer.<br />Vennligst bruk en annen nettleser.</div>
  } */

  return (
    <MuiThemeProvider theme={theme}>
      <HashRouter>
        <CssBaseline />
        <div className={classes.pageContainer}>
          <div className={classes.navbarAndContent}>
            <Navbar withLinkProps={{ url: '/', Link }} pages={pages} variant="styled" />
            <div className={classes.content}>
              <div className={classes.wrapper}>
                <Switch>
                  {Object.keys(routes).map((path) => (
                    <Route path={path}
                      key={path}
                      exact
                      component={routes[path].component} />
                  ))}
                  <Route component={NotFound} />
                </Switch>
              </div>
            </div>
          </div>
          <footer className={classes.footer}>
            <div className={classes.footerContents}>
              <DesktopView>
                VAVIT
                &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                Lars&nbsp;Tore&nbsp;Vassli
                &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
              </DesktopView>
              Tlf.:&nbsp;<a className={classes.footerLink} href="tel:45502534">455&nbsp;02&nbsp;534</a>
              &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
              <a className={classes.footerLink} href="mailto:post@vavit.no">post@vavit.no</a>
              {!isIE && (
                <>
                  &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                  <a className={classes.footerImgLink} href="https://www.youtube.com/channel/UCOgNicZDPkwEDhzlODX4IPg" target="_blank" rel="noopener noreferrer">
                    <div className={classes.footerImgWrapper}>
                      <img src={YoutubeLogo} alt="Youtube" className={classes.footerImg} />
                    </div>
                  </a>
                  &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                  <a className={classes.footerImgLink} href="https://www.facebook.com/vasslivideo" target="_blank" rel="noopener noreferrer">
                    <div className={classes.footerImgWrapper}>
                      <img src={FacebookLogo} alt="Facebook" className={classes.footerImg} />
                    </div>
                  </a>
                </>
              )}
            </div>
          </footer>
        </div>
      </HashRouter>
    </MuiThemeProvider>
  );
};

export default App;
