import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { Box, Button, Paper } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import clsx from 'clsx';
import logo from '../img/logo.png';
import { MobileView, DesktopView } from './Views';

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: '#fff',
    paddingLeft: 'calc(100vw - 100%)',
  },
  appBarContent: {
    maxWidth: '81.3rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: 'auto',
  },
  logo: {
    // float: 'left',
    margin: '.3rem',
    height: '5.4rem',
  },
  styled: {
    boxShadow: '0 0 28px rgba(0,0,0,.5)',
    borderBottom: '1rem solid #4e4f67',
  },
  btn: {
      marginLeft: '1rem',
  },
  link: {
    marginLeft: '1.5rem',
    cursor: 'pointer',
    fontSize: '1.3rem',
  },
  list: {
    width: 150
  },
}));

const Navbar = ({
  variant, withLinkProps, pages
}) => {

  const classes = useStyles();
  const RouterLink = withLinkProps.Link;
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  const list = () => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {/*<ListItem button key="home" onClick={() => {window.location='#'}}>
          <ListItemText primary="Forside" />
        </ListItem>*/}
        {Object.values(pages).map((page) => (
          <ListItem
            button
            key={page.path}
            onClick={() => {window.location=`#/${page.path}`}}
          >
            <ListItemText primary={page.title} />
          </ListItem>
        ))}
      </List>
    </div>
  );


  return (
    <div className={clsx(variant === 'styled' && classes.styled)}>
      <CssBaseline />
      <Paper className={classes.appBar} elevation={0}>
        <Toolbar className={classes.appBarContent}>
          <RouterLink to={withLinkProps.url}>
            <img src={logo} alt="Logo" className={classes.logo} />
          </RouterLink>
            {pages && (
                <Box textAlign='right'>
                    <MobileView>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleDrawer(true)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <SwipeableDrawer
                            anchor="right"
                            open={drawerOpen}
                            onClose={toggleDrawer(false)}
                            onOpen={toggleDrawer(true)}
                            >
                            {list()}
                        </SwipeableDrawer>
                    </MobileView>
                    <DesktopView>
                        {Object.values(pages).map((page) => (
                            <Button variant="outlined"
                                    //color="primary"
                                    key={page.path}
                                    className={classes.btn}
                                    onClick={() => { window.location = `#/${page.path}`; }}>
                                {page.title}
                            </Button>
                            /*<Link
                              color="inherit"
                              className={classes.link}
                              onClick={() => { window.location = `#/${page.path}`; }}>
                              {page.title}
                            </Link>*/
                        ))}
                    </DesktopView>
                </Box>
            )}
          
        </Toolbar>
      </Paper>
    </div>
  );
};

Navbar.propTypes = {
  children: PropTypes.any,
  withLinkProps: PropTypes.object,
  variant: PropTypes.oneOf(['standard', 'styled']),
  language: PropTypes.string,
};

Navbar.defaultProps = {
  children: null,
  withLinkProps: null,
  variant: 'standard',
  language: 'nb',
};


export default Navbar;
