import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';

const DesktopView = ({ children }) => (
  <Hidden mdDown>
    {children}
  </Hidden>
);

DesktopView.propTypes = {
  /** Component to render inside Desktop view */
  children: PropTypes.any,
};

DesktopView.defaultProps = {
  children: null,
};

export default DesktopView;
