import React from 'react';
import { Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const About = () => {
    const classes = useStyles();

    return (
        <Paper className={classes.pageWrapper}>
            <Typography variant="h3" className={classes.header}>Om VAVIT</Typography>

            <Typography variant="body1">
                Vassli Video &amp; IT (VAVIT) er et enkeltpersonforetak drevet av Lars Tore Vassli som tilbyr video- og IT-relaterte tjenester.
                <br />
                <br />
                Kjernevirksomheten omfatter flerkameraproduksjon, livestreaming og videoredigering av kulturarrangement.
                <br />
                <br />
                Enkeltpersonforetaket ble opprettet i 2014 og har tilhørighet i Trøndelag, men kan også ta oppdrag i andre deler av landet.
                <br />
                <br />
                Jeg har en mastergrad i informatikk fra NTNU, i tillegg til en bachelorgrad som dataingeniør fra Høgskolen i Sør-Trøndelag, og jobber til daglig som systemutvikler i Trondheim kommune.
                <br />
                <br />
                For å ta kontakt ang. oppdrag eller andre henvendelser, se <a href="#/kontakt">kontakt-siden</a>.
            </Typography>
        </Paper>
    )
};

const useStyles = makeStyles(() => ({
    pageWrapper: {
        maxWidth: '50rem',
        margin: '0 auto',
        padding: '2rem',
    },
    header: {
        textAlign: 'center',
        marginBottom: '3rem',
    }
}));

export default About;
