import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import clsx from 'clsx';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import TablePaginationActions from './table-pagination-actions';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    boxShadow: 'none',
    padding: 0,
  },
  tableFooter: {
    width: '100%',
  },
  head: {
    fontSize: '1.1rem',
    color: theme.palette.primary.dark,
    padding: '0.5rem',
  },
  firstcell: {
    borderLeft: '0.3rem solid rgba(0, 0, 0, 0)',
  },
  emptyrow: {
    padding: '1.4rem',
  },
}));

const Table = ({
  header, rowsPerPage: initRowsPerPage, children: rows, loading,
  totalRows, fetchRows, hidePagination, hideHeader, className, ...other
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initRowsPerPage);

  useEffect(() => {
    const length = rows ? rows.length : 0;

    if (page > length / rowsPerPage && page !== 0) {
      setPage(0);
    }
  }, [page, rows, rowsPerPage]);

  const length = (totalRows !== -1 && totalRows) || (rows ? rows.length : 0);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, length - page * rowsPerPage);

  const rowsToShow = rows && (hidePagination
    ? rows : rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));

  return (
    <div className={clsx(classes.root, className)}>
      <MuiTable className={classes.table} {...other}>
        {!hideHeader && (
          <TableHead>
            <TableRow>
              {header.map((title, index) => (
                <CustomTableCell key={index.toString()} borderleft={index === 0}>
                  {title}
                </CustomTableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {rowsPerPage === -1 && rows}
          {!loading && rowsToShow}
          {loading && (
            Object.keys(
              Array(emptyRows < rowsPerPage ? rowsPerPage - emptyRows : rowsPerPage).fill(0),
            )
              .map(val => (
                <TableRow key={`skel-${val}`}>
                  {Object.keys(header).map(cval => (
                    <CustomTableCell key={`skel-cell-${cval}`}><Skeleton /></CustomTableCell>
                  ))}
                </TableRow>
              ))
          )}
          {rowsPerPage !== -1 && length > rowsPerPage && emptyRows > 0 && (
            Object.keys(Array(emptyRows).fill(0)).map(val => (
              <TableRow key={`empty-row-${val}`}>
                <TableCell colSpan={6} className={classes.emptyrow} />
              </TableRow>
            ))
          )}
        </TableBody>
        {!hidePagination && (
          <TableFooter className={classes.tableFooter}>
            <TableRow>
              <TablePagination
                colSpan={header.length}
                count={length}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 15, 20]}
                page={page}
                onChangePage={(_, selectedPage) => {
                  if (rows && totalRows > -1 && selectedPage * rowsPerPage >= rows.length) {
                    fetchRows(selectedPage);
                  }
                  setPage(selectedPage);
                }}
                onChangeRowsPerPage={(event) => {
                  setRowsPerPage(parseInt(event.target.value, 10));
                  setPage(0);
                }}
                ActionsComponent={TablePaginationActions}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} av ${count}`}
              />
            </TableRow>
          </TableFooter>
        )}
      </MuiTable>
    </div>
  );
};

Table.propTypes = {
  /** Rows to display per page */
  rowsPerPage: PropTypes.number,
  /** Titles for each column */
  header: PropTypes.arrayOf(PropTypes.any).isRequired,
  /** Tablerow components */
  children: PropTypes.any,
  /** Total rows (for pagination component) */
  totalRows: PropTypes.number,
  /** Handler for fetch more rows. Parameter for selected page included */
  fetchRows: PropTypes.func,
  hidePagination: PropTypes.bool,
  hideHeader: PropTypes.bool,
  className: PropTypes.string,
  loading: PropTypes.bool,
};

Table.defaultProps = {
  rowsPerPage: 10,
  children: undefined,
  totalRows: -1,
  fetchRows: undefined,
  hidePagination: false,
  hideHeader: false,
  className: '',
  loading: false,
};

const CustomTableCell = ({ borderleft, ...props }) => {
  const classes = useStyles();
  return <TableCell className={clsx(classes.head, borderleft && classes.firstcell)} {...props} />;
};

CustomTableCell.propTypes = {
  borderleft: PropTypes.bool.isRequired,
};

export default Table;
