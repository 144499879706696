import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Skeleton from 'react-loading-skeleton';
import Button from '@material-ui/core/Button';
import ExpandLess from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';
import Table from './custom-table';
import { MobileView, DesktopView } from '../Views';
import List, { ListItem } from '../List';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    boxShadow: 'none',
    padding: 0,
  },
  tableFooter: {
    width: '100%',
  },
  table: {
    minWidth: '15rem',
  },
  head: {
    fontSize: '1.1rem',
    color: theme.palette.primary.dark,
    padding: '0.5rem',
  },
  firstcell: {
    borderLeft: '0.3rem solid rgba(0, 0, 0, 0)',
  },
  emptyrow: {
    padding: '1.4rem',
  },
  align: {
    display: 'flex',
  },
  type: {
    marginRight: '0.5rem',
  },
  showMoreButton: {
    marginTop: '0.5rem',
  },
}));

const ResponsiveTable = ({
  header, rowsPerPage, children: rows, loading, showMoreText,
  totalRows, fetchRows, hidePagination, className, excludeColumns, ...other
}) => {
  const classes = useStyles();
  const [lastItemIndex, setLastItemIndex] = useState(rowsPerPage);

  const calculatedTotalRows = rows.length;
  return (
    <div className={clsx(classes.root, className)}>
      <DesktopView>
        <Table
          header={header}
          rowsPerPage={rowsPerPage}
          initRowsPerPage={rowsPerPage}
          loading={loading}
          totalRows={totalRows}
          fetchRows={fetchRows}
          hidePagination={hidePagination}
          className={className}
          {...other}
        >
          {rows}
        </Table>
      </DesktopView>
      <MobileView>
        <List>
          {rows.slice(0, hidePagination ? -1 : lastItemIndex).map(item => (
            <ListItem primary={
              item.props.children.map((child, i) => {
                if (excludeColumns.indexOf(header[i]) !== -1) return null;
                if (loading) return <Skeleton />;
                return (
                  <div className={classes.align}>
                    <Typography variant="body1" color="primary" className={classes.type}>
                      {`${header[i]}:`}
                    </Typography>
                    <Typography variant="body1">
                      {child.props.children}
                    </Typography>
                  </div>
                );
              })
            }
            />
          ))}
        </List>
        {!hidePagination && (
          <div className={classes.align}>
            <Button
              className={classes.showMoreButton}
              variant="outlined"
              fullWidth
              color="primary"
              onClick={() => setLastItemIndex(lastItemIndex + 5)}
              disabled={lastItemIndex + 1 >= calculatedTotalRows}
            >
              {showMoreText}
            </Button>
            {lastItemIndex > rowsPerPage && (
              <IconButton onClick={() => setLastItemIndex(rowsPerPage)}>
                <ExpandLess color="primary" />
              </IconButton>
            )}
          </div>
        )}
      </MobileView>

    </div>
  );
};

ResponsiveTable.propTypes = {
  /** Rows to display per page */
  rowsPerPage: PropTypes.number,
  /** Titles for each column */
  header: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** Tablerow components */
  children: PropTypes.any,
  /** Total rows (for pagination component) */
  totalRows: PropTypes.number,
  /** Handler for fetch more rows. Parameter for selected page included */
  fetchRows: PropTypes.func,
  hidePagination: PropTypes.bool,
  className: PropTypes.string,
  loading: PropTypes.bool,
  excludeColumns: PropTypes.array,
  showMoreText: PropTypes.string,
};

ResponsiveTable.defaultProps = {
  rowsPerPage: 10,
  children: undefined,
  totalRows: -1,
  fetchRows: undefined,
  hidePagination: false,
  className: '',
  loading: false,
  excludeColumns: [],
  showMoreText: 'Vis mer',
};


export default ResponsiveTable;
